
<template>
    <svg width="14px" height="40px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
        <title>0BF09853-687B-4C84-9CEE-03BB076DB253</title>
        <desc>Created with sketchtool.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Spec" transform="translate(-756.000000, -554.000000)" stroke="blue" class="smallSerachstroke" stroke-width="2.5">
                <g id="Search" transform="translate(756.357143, 554.418717)">
                    <path d="M8.26978897,2.2472986 C7.43825657,1.4157662 6.34840018,1 5.25854378,1 C4.16868739,1 3.07883099,1.4157662 2.2472986,2.2472986 C1.4157662,3.07883099 1,4.16868739 1,5.25854378 C1,6.34840018 1.4157662,7.43825657 2.2472986,8.26978897 C2.9683213,8.99081167 3.88340389,9.39893546 4.83071357,9.50406358 C6.05933991,9.64041094 7.34242718,9.26988516 8.33767894,8.41080617 L11.6811239,11.6079967 L8.30130786,8.2377094 C9.22063729,7.30034316 9.61767827,6.04336815 9.4955582,4.82926798 C9.4007324,3.88652353 8.99198905,2.96949867 8.26978897,2.2472986 Z"></path>
                </g>
            </g>
        </g>
    </svg>
    
    </template>
    
    <script>
    export default{
        name:"smallSerach",
    }
    </script>
    
    <style lang="scss">
      @import "@/sass/_variables.scss";
      @import "@/sass/_components.scss";
    
    .smallSerachstroke{
    //  stroke:;
     stroke: $clr-small-stroke;
    }
    </style>